import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { useMediaQuery } from 'usehooks-ts';
import { Layout, SEO } from '../components';
import DocumentsList from '../components/documents/DocumentsList';
import Clouds from '../assets/images/clouds.svg';
import PasswordProtect from '../components/PasswordProtect';
import { getSessionPassword } from '@src/utils/cookie';
import { CountdownTimer } from '../components/UI/FlipClock';
import { scroller } from 'react-scroll';

import { setModalTimeout } from './../store/sub_modal';
import VideoPlayer from '../components/VideoPlayer';

const correctPassword = getSessionPassword() === process.env.GATSBY_FOLDER_PASSWORD || false;

const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

const ModuleSubCategoryDocumentsPage = ({
  data: {
    timers,
    subCategoryOption,
    subCategoryPage: { module, heading: subPageCategoryHeading },
    datocms: {
      docCategoryPage,
      documentsCount: { count: totalDocuments },
    },
  },
  pageContext: { documents },
}) => {
  process.env.NODE_ENV == 'development' &&
  console.log(
    '🚀 module-sub-category-sub-category-options-documents-level2.js',
  );


  const dispatch = useDispatch();
  const screen479 = useMediaQuery('(max-width: 479px)');

  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  const showSubscriptionButton =
    subCategoryOption?.edges?.[0]?.node?.subscriptionforumbuttons?.[0]?.displaysubscription ??
    false;
  const showForumButton =
    subCategoryOption?.edges?.[0]?.node?.subscriptionforumbuttons?.[0]?.displayforum ?? false;
  const subscriptionBackgroundColor =
    subCategoryOption?.edges?.[0]?.node?.subscriptionforumbuttons?.[0]?.subbackgroundcolor?.hex ??
    '#23D4FF';
  const subscriptionTextColor =
    subCategoryOption?.edges?.[0]?.node?.subscriptionforumbuttons?.[0]?.subtextcolor?.hex ??
    '#000000';
  const forumBackgroundColor =
    subCategoryOption?.edges?.[0]?.node?.subscriptionforumbuttons?.[0]?.forumbackgroundcolor?.hex ??
    '#FD99C4';
  const forumTextColor =
    subCategoryOption?.edges?.[0]?.node?.subscriptionforumbuttons?.[0]?.forumtextcolor?.hex ??
    '#000000';

  const {
    description,
    description2,
    seo,
    seoKeywords,
    subCategoryOptionHeading: heading,
    isLocked,
    containsSubscription,
    video,
    videos,
    youtubeVideo,
  } = subCategoryOption.edges[0].node;

  let stillLocked = isLocked;

  if ((isLocked && correctPassword) || isSubscribed) {
    stillLocked = false;
  }

  if (stillLocked && containsSubscription && !isSubscribed) {
    dispatch(setModalTimeout(1));
  }

  const combinedDocuments = React.useMemo(
    () => documents.filter((doc) => !doc.isFeatured && !doc.freeSample),
    [documents],
  );
  const featuredDocuments = React.useMemo(
    () => documents.filter((doc) => doc.isFeatured),
    [documents],
  );
  const freeDocuments = React.useMemo(
    () => documents.filter((doc) => doc.freeSample && !doc.freeDocument),
    [documents],
  );

  const docCategories = React.useMemo(() => {
    const objDocCategories = {};
    docCategoryPage?.docCategories.map(({ id, name }) => {
      objDocCategories[slugify(name)] = {
        name,
        docs: combinedDocuments.filter((doc) => doc.docCategory?.originalId == id),
      };
    });

    return objDocCategories;
  }, [combinedDocuments]);

  const uncategorisedDocs = React.useMemo(
    () =>
      combinedDocuments.filter(
        (doc) =>
          !doc.docCategory || !docCategories.hasOwnProperty(slugify(doc.docCategory?.name || '')),
      ),
    [combinedDocuments],
  );

  const orderedTimers = React.useMemo(() => {
    return timers?.group?.map(({ edges, examBoard }) => {
      const groups = {};
      for (const {
        node,
        node: { title },
      } of edges) {
        const regex = /\d([A-Za-z])(?![A-Za-z])/;
        const matched = title?.match(regex);

        if (!matched) {
          if (!groups.unmatched) groups.unmatched = [];
          groups.unmatched = [...groups.unmatched, node];
          continue;
        }

        const [_, letter] = matched;
        if (!groups[letter]) {
          groups[letter] = [];
        }
        groups[letter].push(node);
      }
      const formattedEdges = Object.values(groups)
        .map((papers) => papers.sort((a, b) => b.title - a.title))
        .flat();
      return {
        edges: formattedEdges,
        examBoard,
      };
    });
  }, [timers]);

  const numOfTimers = orderedTimers.reduce((total, { edges }) => (total += edges?.length ?? 0), 0);

  const renderFeaturedDocuments = () => {
    return (
      !!featuredDocuments.length && (
        <div className="documents-list">
          <div className="category-breadcrumb-heading">
            <h3>Featured documents:</h3>
          </div>
          <DocumentsList documents={featuredDocuments} />
        </div>
      )
    );
  };

  const renderFreeDocuments = () =>
    freeDocuments.length > 0 && (
      <div className="documents-list">
        <div className="category-breadcrumb-heading">
          <h3>Free samples:</h3>
        </div>
        <DocumentsList documents={freeDocuments} />
      </div>
    );

  const renderDocumentsHeading = () =>
    documents[0] && (
      <div className="category-breadcrumb-heading">
        <p>Total of {totalDocuments}</p>
      </div>
    );

  const renderCategorizedDocs = () => {
    return (
      <>
        {Object.keys(docCategories).map((key) => {
          const category = docCategories[key];
          return (
            <>
              {!!category.docs.length ? (
                <div className={`scroll-doc-category-${key}`} key={key}>
                  <h3
                    style={{
                      textAlign: 'center',
                      marginBottom: '35px',
                      marginTop: '35px',
                    }}
                  >
                    {category.name}
                  </h3>
                  <DocumentsList documents={category.docs} />
                </div>
              ) : (
                <></>
              )}
            </>
          );
        })}
        {!!uncategorisedDocs.length && (
          <div>
            <div style={{ textAlign: 'center', marginBottom: '100px', marginTop: '35px' }}>
              {/* Uncategorised */}
            </div>
            <DocumentsList documents={uncategorisedDocs} />
          </div>
        )}
      </>
    );
  };

  const renderDocCategoryButtons = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '70%', flexWrap: 'wrap' }}>
          {Object?.keys(docCategories)?.map((key) => {
            const item = docCategories[key];
            const hasDocs = docCategories[key].docs.length > 0;
            return (
              <button
                key={item.id}
                style={{
                  marginTop: '7px',
                  marginRight: '7px',
                  backgroundColor: hasDocs ? '#fd99c5' : '#fd99c580',
                  color: 'white',
                  fontWeight: 'bold',
                  padding: '3px',
                }}
                disabled={!hasDocs}
                onClick={() => {
                  scroller.scrollTo(`scroll-doc-category-${slugify(item.name)}`, {
                    duration: 1000,
                    smooth: 'easeOutQuad',
                    offset: -50,
                  });
                }}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderActionButtons = () => {
    if (!showSubscriptionButton && !showForumButton) return;
    return (
      <div className="action-container">
        {showSubscriptionButton && !isSubscribed && (
          <button
            onClick={() => navigate('/subscriptions')}
            className="w-nav-brand sign-in-badge forum-sub-button"
            style={{
              backgroundColor: subscriptionBackgroundColor,
              color: subscriptionTextColor,
            }}
          >
            <span class="2">Join Now</span>
          </button>
        )}
        {showForumButton && (
          <button
            onClick={() => navigate('/forum')}
            className="w-nav-brand sign-in-badge forum-sub-button"
            style={{
              backgroundColor: forumBackgroundColor,
              color: forumTextColor,
            }}
          >
            <span class="2">Forum</span>
          </button>
        )}
      </div>
    );
  };

  const allVideos = [
    ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
    ...(youtubeVideo ? youtubeVideo.split(',') : []),
    ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
  ];

  return (
    <MathJaxContext>
      <Layout>
        <SEO
          description={seo && seo.description}
          image={seo && seo.image}
          title={seo && seo.title}
          seoKeywords={seoKeywords}
        />
        <div>
          <div>
            <div className="container">
              <div className={`srow mobile:x-centered ${numOfTimers < 4 ? 'x-centered' : ''}`}>
                {!!orderedTimers?.length &&
                  orderedTimers.map(
                    ({ edges }) =>
                      !!edges?.length &&
                      edges.map(({ endDate, title, daysOnly }, k) => (
                        <div className="column narrow mb-1" key={`clock ${k}`}>
                          <CountdownTimer endDate={endDate} title={title} daysOnly={daysOnly} />
                        </div>
                      )),
                  )}
              </div>
              <div className="grid hero" style={{ paddingTop: '50px' }}>
                <div className="content-block" style={{ minHeight: screen479 ? 'unset' : '630px' }}>
                  {/* <div style={{ marginBottom: '5px' }}> */}
                  {!screen479 && module.image?.url && (
                    <img
                      src={module.image.url}
                      alt={module.image.alt}
                      style={{ float: 'right', width: '60%' }}
                    />
                  )}
                  {renderActionButtons()}
                  <a className="page-breadcrums" href={`/modules/${module.slug}`}>
                    {module.title}
                  </a>
                  {' > '}
                  <a
                    className="page-breadcrums"
                    href={`/modules/${module.slug}/${subPageCategoryHeading.slug}`}
                  >
                    {subPageCategoryHeading.name}
                  </a>
                  {/* </div> */}
                  <h1 className="content-h2">{heading.name}</h1>
                  {allVideos.length
                    ? allVideos.map((src) => (
                        <VideoPlayer className="video-player" isSmall src={src} />
                      ))
                    : null}
                  {/* <h1 className="content-h2">{module.title}</h1> */}
                  {/* <h2>{heading.name}</h2> */}
                  <br />
                  <MathJax hideUntilTypeset="first">
                    {description && <ReactMarkdown className="paragraph" children={description} />}
                    {!description && description2 && (
                      <div dangerouslySetInnerHTML={{ __html: description2 }} />
                    )}
                  </MathJax>
                </div>
              </div>
            </div>
            {screen479 && module.image?.url && (
              <img src={module.image.url} alt={module.image.alt} />
            )}
          </div>
          {stillLocked === true && <PasswordProtect />}
          {!stillLocked && (
            <article className="content-section">
              <div className="content-block container" style={{ clear: 'both' }}>
                {renderFeaturedDocuments()}
                {renderFreeDocuments()}
                {docCategoryPage?.isEnabled && renderDocCategoryButtons()}
                {renderDocumentsHeading()}
                {docCategoryPage?.isEnabled && renderCategorizedDocs()}
                {!docCategoryPage?.isEnabled && (
                  <DocumentsList documents={combinedDocuments} showNoData type={heading.name} />
                )}
              </div>
            </article>
          )}
        </div>
      </Layout>
    </MathJaxContext>
  );
};

export default ModuleSubCategoryDocumentsPage;

const query = graphql`
  query SUB_CATEGORY_SUB_CATEGORY_OPTIONS_DOCUMENTS_QUERY2(
    $module: DATOCMS_ItemId
    $id: String!
    $subCategoryOptionId: String
    $categories: [DATOCMS_ItemId]
    $pathName: String
  ) {
    timers: allDatoCmsCountdownTimer(filter: { path: { eq: $pathName } }, sort: { fields: title }) {
      group(field: examBoard) {
        edges {
          node {
            daysOnly
            endDate
            examBoard
            path
            title
          }
        }
        examBoard: fieldValue
      }
    }
    subCategoryOption: allDatoCmsSubCategoryOption(filter: { id: { eq: $subCategoryOptionId } }) {
      edges {
        node {
          id
          description
          description2
          seo {
            description
            title
            image {
              url
            }
          }
          video {
            video {
              streamingUrl
            }
          }
          videos {
            video {
              streamingUrl
            }
          }
          youtubeVideo
          subscriptionforumbuttons {
            displayforum
            displaysubscription
            subbackgroundcolor {
              hex
            }
            subtextcolor {
              hex
            }
            forumbackgroundcolor {
              hex
            }
            forumtextcolor {
              hex
            }
          }
          seoKeywords {
            keyword
          }
          isLocked
          containsSubscription
          subCategoryOptionHeading {
            slug
            name
            originalId
            id
          }
        }
      }
    }
    subCategoryPage: datoCmsModuleSubCategoryPage(id: { eq: $id }) {
      module {
        title
        slug
        image {
          alt
          url
        }
      }
      heading {
        name
        slug
      }
    }
    datocms {
      documentsCount: _allDocumentsMeta(
        filter: {
          documentCategory: { allIn: $categories }
          module: { eq: $module }
          isFeatured: { eq: "false" }
          freeSample: { eq: "false" }
        }
      ) {
        count
      }
      docCategoryPage: docCategoryPage(
        filter: { module: { eq: $module }, page: { allIn: $categories } }
      ) {
        docCategories {
          name
          id
        }
        isEnabled
      }
    }
  }
`;
export { query };
